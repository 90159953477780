<template>
  <div class="container-fluid">
      <b-row>
        <b-col sm="12" md="7" lg="7" xl="7" class="bg-login align-items-center d-none d-lg-flex">
          <div class="ml-5">
            <img alt="WYA Yoga" src="../../assets/images/logo/logo.png">
            <h1 class="text-white mt-3 font-weight-bold display-3">{{ $t('WORLD YOGA ALLIANCE') }}</h1>
            <h5 class="text-white mt-4">{{ $t('International Yoga Organization') }}</h5>
            <br>
            <b-button pill variant="light" :to="{ name: 'home' }" class="mr-3 px-5 my-4 font-weight-bold header-btn-register">{{ $t('Learn more') }}</b-button>
            <b-button pill variant="outline-light" :to="{ name: 'home' }" class="mr-3 px-5 my-4 ml-2 font-weight-bold header-btn-register">{{ $t('Search') }}</b-button>
          </div>
        </b-col>
        <b-col sm="12" md="5" lg="5" xl="5" class="d-flex justify-content-center align-items-center">
          <div class="mt-5 mt-lg-0">
            <div class="d-flex justify-content-center"><h1 class="text-primary-light d-inline font-weight-bold header-login">{{ $t('Reset Password') }}</h1></div>
            <p class="text-center text-gray-light mt-5" v-html="$t('Enter your email or username and we’ll send you a link to get back into your account.')"></p>
            <div class="mt-5">
              <ValidationObserver ref="form" v-slot="{ handleSubmit }">
                <b-form @submit.stop.prevent="handleSubmit(onSubmit)">
                  <validation-provider
                    :name="$t('Email')"
                    rules="required|email"
                    v-slot="{errors}"
                  >
                  <b-form-group class="font-weight-bold text-primary-dark" for="input-email" :label="$t('Your email')">
                    <b-form-input
                      id="input-email"
                      class="input-login"
                      type="text"
                      v-model="form.email"
                      :placeholder="$t('Email')">
                    </b-form-input>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                  </validation-provider>
                  <b-button pill block type="submit" :disabled="isLoading" class="mr-3 mt-2 px-5 my-4 font-weight-bold btn-primary-dark"><b-spinner small class="mr-3" v-if="isLoading"  label="Loading..."></b-spinner>{{ $t('Confirm') }}</b-button>
                </b-form>
              </ValidationObserver>
              <router-link :to="{ name: 'authenSignup' }" class="d-block text-danger-light mt-3 text-center">{{ $t('Create New Account') }}</router-link>
            </div>
            <div class="text-center">
              <span class="text-gray-light d-block mt-5">
                <small class="text-gray-light">Copyright © World Yoga Alliance® 1990 - {{ currentYear }}</small>
              </span>
            </div>
          </div>
        </b-col>
      </b-row>
  </div>
</template>
<script>
import Api from '../../api/api'

export default {
  name: 'ResetPassword',
  title: ' | Reset Password',
  data () {
    return {
      currentYear: new Date().getFullYear(),
      form: {
        email: ''
      },
      isLoading: false,
      isSendingEmail: false
    }
  },
  methods: {
    onSubmit () {
      this.$refs.form.validate().then(result => {
        if (result) {
          this.isLoading = true
          try {
            Api.resetpassword(this.form)
              .then(response => {
                if (response.status === 200) {
                  requestAnimationFrame(() => {
                    this.$refs.form.reset()
                  })
                  this.$notify({
                    group: 'success',
                    title: this.$t('Reset Password'),
                    text: this.$t('Send email reset password successfully')
                  })
                }
                this.isLoading = false
              })
              .catch(() => {
                this.$notify({
                  group: 'warning',
                  title: this.$t('Reset Password'),
                  text: this.$t('Send email reset password unsuccessfully')
                })
                this.isLoading = false
              })
          } catch (error) {
            this.$notify({
              group: 'warning',
              title: this.$t('Reset Password'),
              text: this.$t('Send email reset password unsuccessfully')
            })
            this.isLoading = false
          }
        }
      })
    }
  }
}
</script>
<style scoped>
  .bg-login {
    background-image: url('../../assets/images/banner/bg_resetpassword.png');
    background-repeat: no-repeat;
    background-size: auto;
    min-height: 900px;
  }
</style>
